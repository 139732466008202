<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="memberGradeChangePopup"
      header="회원등급 확정처리"
      allowDragging="true"
      showCloseIcon="true"
      width="300"
      height="213"
      isModal="true"
      @close="onMemberGradeChangePopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section">
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <grid
                        ref="grid"
                        v-bind="gridProps"
                        style="height: 74.5px"
                        :visibleHeader="false"
                        :pageable="false"
                        :enableSelectCell="false"
                        :enableScrollbar="false"
                      />
                      <p>회원등급 확정 처리 하시겠습니까?</p>
                    </div>
                  </div>
                  <ul class="lookupDetail-condition">
                    <li class="field">
                      <ul class="content">
                        <li class="item">
                          <ul class="check">
                            <li>
                              <label>
                                <input
                                  type="checkbox"
                                  id="sendFailViewFlag"
                                  v-model="isCouponPub"
                                  :disabled="!isNotNullCouponKindId"
                                />
                                <i></i>
                                <div class="label">쿠폰발행</div>
                              </label>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                button-div="SAVE"
                :is-shortcut-button="true"
                @click.native="confirmButtonClicked"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="closeMemberGradeChangePopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .section-body {overflow: hidden;}
body .article-section {padding-bottom: 0;}
body .lookupDetail-condition {margin: 0;padding: 0;}
body .lookupDetail-condition .field {padding: 0;}
body .lookupDetail-condition .content,
body .lookupDetail-condition .item {padding-left: 0;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";

import Grid from "@/components/grid/Grid";

import {
  commonCodesGetComName,
  commonCodesGetColorValue,
} from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "MemberGradeChangePopup",
  components: {
    Grid,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      popupData: {
        bsnDateFrom: null,
        bsnDateTo: null,
        visitCnt: null,
        memberGrades: [],
        membershipId: null,
        memberName: null,
        memberGradeChanges: [],
        filters: null,
        orders: null,
      },
      gridData: [],
      isCouponPub: true,
      isMemberChangeSuccessFlag: false,
      isNotNullCouponKindId: null,
    };
  },
  computed: {
    gridProps() {
      return {
        columns: [
          {
            name: "",
            field: "title",
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            name: "",
            field: "value",
            type: String,
            cellStyle: {
              textAlign: "right",
            },
          },
        ],
        records: this.gridData,
        sortable: false,
        filterable: false,
        visibleRowNumberColumn: false,
      };
    },
  },
  async created() {},
  methods: {
    numberWithCommas,
    commonCodesGetComName,
    commonCodesGetColorValue,
    showPopup(popupData) {
      this.popupData = popupData;
      this.onViewButtonClicked();
    },
    onMemberGradeChangePopupClosed() {
      this.$emit("popupClosed", { isReload: this.isMemberChangeSuccessFlag });
    },
    closeMemberGradeChangePopup() {
      this.$refs.memberGradeChangePopup.hide();
    },
    async onViewButtonClicked() {
      const args = {
        bsnDateFrom: this.popupData.bsnDateFrom,
        bsnDateTo: this.popupData.bsnDateTo,
        visitCnt: this.popupData.visitCnt,
        memberGrades: this.popupData.memberGrades,
        membershipId: this.popupData.membershipId,
        memberName: this.popupData.memberName,
        memberGradeChanges: this.popupData.memberGradeChanges,
        limit: 0,
        offset: 0,
        filters: this.popupData.filters,
        orders: this.popupData.orders,
      };

      const {
        members,
      } = await GolfErpAPI.fetchMemberGradeManagements(args);

      const memberGradeChangeUp = members.filter(
        (member) => member.memberGradeChangeCode === "UP"
      );
      const memberGradeChangeDown = members.filter(
        (member) => member.memberGradeChangeCode === "DOWN"
      );
      const memberGradeChangeNor = members.filter(
        (member) => member.memberGradeChangeCode === "NOR"
      );

      this.gridData = [
        {
          title: commonCodesGetComName("MEMBER_GRADE_CHANGE", "UP"),
          value: `${numberWithCommas(memberGradeChangeUp.length)} 명`,
        },
        {
          title: commonCodesGetComName("MEMBER_GRADE_CHANGE", "DOWN"),
          value: `${numberWithCommas(memberGradeChangeDown.length)} 명`,
        },
        {
          title: commonCodesGetComName("MEMBER_GRADE_CHANGE", "NOR"),
          value: `${numberWithCommas(memberGradeChangeNor.length)} 명`,
        },
      ];
      this.isNotNullCouponKindId = members.find(
        (member) => member.couponKindId
      );
    },
    async confirmButtonClicked() {
      const args = {
        bsnDateFrom: this.popupData.bsnDateFrom,
        bsnDateTo: this.popupData.bsnDateTo,
        visitCnt: this.popupData.visitCnt,
        memberGrades:
          this.popupData.memberGrades.length > 0
            ? this.popupData.memberGrades
            : null,
        membershipId: this.popupData.membershipId
          ? this.popupData.membershipId
          : null,
        memberName: this.popupData.memberName
          ? this.popupData.memberName
          : null,
        memberGradeChanges:
          this.popupData.memberGradeChanges.length > 0
            ? this.popupData.memberGradeChanges
            : null,
        isCouponPub: this.isCouponPub,
        limit: 0,
        offset: 0,
        filters: this.popupData.filters,
        orders: this.popupData.orders,
      };

      try {
        await GolfErpAPI.putMemberGradeChange(args);

        this.infoToast("회원등급 확정 처리되었습니다.");
      } catch (e) {
        console.log("error.===>", e);
        this.errorToast(this.$t("main.popupMessage.serverError"));
      }

      this.isMemberChangeSuccessFlag = true;

      this.closeMemberGradeChangePopup();
    },
  },
};
</script>
