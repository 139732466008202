<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="memberGradeChangeLogPopup"
      header="회원등급 변경이력"
      allowDragging="true"
      showCloseIcon="true"
      width="1180"
      height="800"
      isModal="true"
      @close="onMemberGradeChangeLogPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">회원명 : {{ memberName }}</div>
                  </li>
                  <li class="field">
                    <div class="title">
                      회원등급 :
                      {{ commonCodesGetComName("MEMBER_GRADE", memberGrade) }}
                    </div>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                    button-div="GET"
                    :is-shortcut-button="true"
                    :ignore="
                      commonMixinIsButtonDisableByAuth(
                        'memberGradeManagementGet'
                      )
                    "
                    @click.native="onViewButtonClicked"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">변경이력</div>
                      <div class="header-caption">
                        [{{ numberWithCommas(logsLength) }}건]
                      </div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <grid ref="logGrid" v-bind="logGridProps" />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="closeMemberGradeChangeLogPopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";

import Grid from "@/components/grid/Grid";

import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import { orderBy as _orderBy } from "lodash";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import MemberGradeChangeCellTemplate from "@/views/member-management/template/MemberGradeChangeCellTemplate";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "MemberGradeChangeLogPopup",
  components: {
    Grid,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      memberName: null,
      membershipId: null,
      memberGrade: null,
      logsLength: 0,
      logs: [],
    };
  },
  computed: {
    logGridProps() {
      return {
        columns: [
          {
            minWidth: 16,
            name: "변경일자",
            width: 90,
            field: "changeDate",
            sortable: false,
            filterable: false,
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            name: "기준일자",
            columns: [
              {
                minWidth: 16,
                name: "시작일자",
                width: 90,
                field: "stdStartDate",
                sortable: false,
                filterable: false,
                type: String,
                cellStyle: {
                  textAlign: "center",
                },
              },
              {
                minWidth: 16,
                name: "종료일자",
                width: 90,
                field: "stdEndDate",
                sortable: false,
                filterable: false,
                type: String,
                cellStyle: {
                  textAlign: "center",
                },
              },
            ],
          },
          {
            name: "회원등급",
            columns: [
              {
                minWidth: 16,
                name: "현재",
                width: 90,
                field: "befoMemberGrade",
                sortable: false,
                filterable: false,
                type: String,
                format: (data) =>
                  commonCodesGetCommonCode("MEMBER_GRADE", false)?.find(
                    ({ comCode }) => comCode === data
                  )?.comName,
                cellStyle: () => {
                  return {
                    textAlign: "center",
                  };
                },
              },
              {
                minWidth: 16,
                name: "변경",
                width: 90,
                field: "changeMemberGrade",
                sortable: false,
                filterable: false,
                type: String,
                format: (data) =>
                  commonCodesGetCommonCode("MEMBER_GRADE", false)?.find(
                    ({ comCode }) => comCode === data
                  )?.comName,
                cellStyle: (data) => {
                  return {
                    textAlign: "center",
                    color: data.colorValue,
                  };
                },
              },
            ],
          },
          {
            minWidth: 16,
            name: "변경구분",
            width: 70,
            field: "memberGradeChangeCodeAbrv",
            sortable: false,
            filterable: false,
            type: String,
            iteratee: ({ memberGradeChange }) => {
              return commonCodesGetCommonCode(
                "MEMBER_GRADE_CHANGE",
                false
              )?.find(({ comCode }) => comCode === memberGradeChange);
            },
            cellTemplate: MemberGradeChangeCellTemplate,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            name: "회원실적",
            columns: [
              {
                minWidth: 16,
                name: "내장횟수",
                width: 70,
                field: "visitCnt",
                sortable: false,
                filterable: false,
                type: Number,
                format: (data) => {
                  return data === 0 ? "-" : numberWithCommas(data);
                },
                cellStyle: {
                  textAlign: "right",
                },
              },
              {
                minWidth: 16,
                name: "내장점수",
                width: 70,
                field: "visitScore",
                sortable: false,
                filterable: false,
                type: Number,
                format: (data) => {
                  return data === 0 ? "-" : numberWithCommas(data);
                },
                cellStyle: {
                  textAlign: "right",
                },
              },
              {
                minWidth: 16,
                name: "입장료",
                width: 90,
                field: "feeSales",
                sortable: false,
                filterable: false,
                type: Number,
                format: (data) => {
                  return data === 0 ? "-" : numberWithCommas(data);
                },
                cellStyle: {
                  textAlign: "right",
                },
              },
              {
                minWidth: 16,
                name: "식음매출",
                width: 90,
                field: "foodSales",
                sortable: false,
                filterable: false,
                type: Number,
                format: (data) => {
                  return data === 0 ? "-" : numberWithCommas(data);
                },
                cellStyle: {
                  textAlign: "right",
                },
              },
              {
                minWidth: 16,
                name: "프로샵",
                width: 90,
                field: "shopSales",
                sortable: false,
                filterable: false,
                type: Number,
                format: (data) => {
                  return data === 0 ? "-" : numberWithCommas(data);
                },
                cellStyle: {
                  textAlign: "right",
                },
              },
            ],
          },
          {
            minWidth: 16,
            name: "확정자",
            width: 90,
            field: "insertName",
            sortable: false,
            filterable: false,
            type: String,
          },
          {
            minWidth: 16,
            name: "확정일시",
            width: 130,
            field: "insertDt",
            sortable: false,
            filterable: false,
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
        ],
        records: this.logs,
        sizeOfRecordPerPage: 50,
        countOfAllRecords: this.logsLength,
        rangeOfPages: 10,
        sortable: false,
        filterable: false,
      };
    },
  },
  async created() {},
  methods: {
    numberWithCommas,
    commonCodesGetComName,
    showPopup(popupData) {
      this.membershipId = popupData.membershipId;
      this.memberName = popupData.memberName;
      this.memberGrade = popupData.memberGrade;

      this.onViewButtonClicked();
    },
    onMemberGradeChangeLogPopupClosed() {
      this.$emit("popupClosed");
    },
    closeMemberGradeChangeLogPopup() {
      this.$refs.memberGradeChangeLogPopup.hide();
    },
    async onViewButtonClicked() {
      const { total: totalLogs, logs } = await GolfErpAPI.fetchTgLogMemberGrade(
        this.membershipId
      );

      this.logsLength = totalLogs;

      this.logs = _orderBy(logs, ["insertDt"], ["desc"]);
    },
  },
};
</script>
