<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">기준일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  v-model="inputDateRangeValue"
                  type="lookup-condition"
                />
              </li>
              <li class="item button">
                <ul class="button">
                  <li>
                    <ejs-dropdownbutton
                      ref="inputDateFromMenuDropdownButton"
                      cssClass="lookup-button-dropdown"
                      :items="inputDateFromMenuItems"
                      @select="inputDateFromMenuSelected"
                    >
                      {{ inputDateFromMenuItemsLabel || "날짜" }}
                    </ejs-dropdownbutton>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field visitCnt">
            <div class="title">내장횟수</div>
            <ul class="content">
              <li class="item input">
                <input-number
                  v-model="searchConditions.visitCnt"
                  :min="0"
                  :propMaxLength="3"
                />
              </li>
            </ul>
            <div class="title">회 이상</div>
          </li>
          <li class="field memberGrade">
            <div class="title">현재회원등급</div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect
                  cssClass="lookup-condition-multiselect"
                  v-model="searchConditions.memberGrades"
                  v-bind="memberGradeMultiSelectProps"
                />
              </li>
            </ul>
          </li>
          <li class="field memberGradeChange">
            <div class="title">변경구분</div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect
                  cssClass="lookup-condition-multiselect"
                  v-model="searchConditions.memberGradeChanges"
                  v-bind="memberGradeChangesMultiSelectProps"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <div class="title">회원검색</div>
              <li class="item">
                <input-text
                  v-model="searchConditions.memberName"
                  @change="onMemberNameChanged"
                  @keydown.enter="onMemberNameKeyDownEnter"
                />
              </li>
            </ul>
            <ul class="content">
              <li class="item button">
                <ul class="button">
                  <li class="search">
                    <erp-button
                        button-div="GET"
                      @click.native="onMemberSearchButtonClicked"
                    >
                      검색
                    </erp-button>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isOpenPopup"
              :is-shortcut-button="true"
              @click.native="onSearchButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <ul class="lookup-button">
          <li class="shortcut">
            <ejs-dropdownbutton
              ref="shortcutMenuDropdownButton"
              cssClass="lookup-button-dropdown"
              :items="shortcutMenuItems"
              @select="shortcutMenuSelected"
            >
              바로가기
            </ejs-dropdownbutton>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">회원정보 목록</div>
              <div class="header-caption">
                [ {{ totalMembers | numberWithCommas }} 건 ]
              </div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li>
                  <erp-button
                    button-div="SAVE"
                    :is-icon-custom="true"
                    @click.native="onMemberGradeChangeButtonClicked"
                  >
                    회원등급 확정
                  </erp-button>
                </li>
                <li>
                  <erp-button
                      button-div="FILE"
                     :disabled="isMemberGradeManagementExcelDownloading"
                     @click.native="onExcelClick"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <grid
                ref="memberGrid"
                v-bind="memberGridProps"
                @filtered="onMemberGridDataFiltered"
                @sorted="onMemberGridDataFiltered"
                @paged="onMemberGridDataFiltered"
                @cellClick="onMemberGridCellClick"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    />
    <member-grade-change-log-popup
      v-if="isMemberGradeChangeLogPopupOpen"
      ref="memberGradeChangeLogPopup"
      @popupClosed="memberGradeChangeLogPopupClosed"
    />
    <member-grade-change-popup
      v-if="isMemberGradeChangePopupOpen"
      ref="memberGradeChangePopup"
      @popupClosed="memberGradeChangePopupClosed"
    />
    <config-member-grade-setting-popup
      ref="configMemberGradeSettingPopup"
      v-if="isConfigMemberGradePopupOpen"
      @popupClosed="onConfigMemberGradePopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.visitCnt .content .item.input {width: 60px;}
body .appContent .lookup-condition .field.memberGrade .content .item {width: 300px;}
body .appContent .lookup-condition .field.memberGradeChange .content .item {width: 150px;}
</style>

<script>
import { sortBy as _sortBy } from "lodash";
import commonMixin from "@/views/layout/mixin/commonMixin";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import InputText from "@/components/common/text/InputText";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import InputNumber from "@/components/common/InputNumber";
import memberSelectPopup from "@/views/common/MemberSelectPopup";
import Grid from "@/components/grid/Grid";
import MemberGradeChangeLogPopup from "@/views/member-management/popup/MemberGradeChangeLogPopup";
import MemberGradeChangePopup from "@/views/member-management/popup/MemberGradeChangePopup";
import ConfigMemberGradeSettingPopup from "@/views/code-management/popup/ConfigMemberGradeSettingPopup";
import MemberGradeChangeCellTemplate from "@/views/member-management/template/MemberGradeChangeCellTemplate";
import ErpButton from "@/components/button/ErpButton.vue";

import {
  commonCodesGetColorValue,
  commonCodesGetCommonCode,
  commonCodesGetStandardInfo,
} from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import { openNewWindow } from "@/utils/appInfo";
import { getFormattedDate } from "@/utils/date";

import moment from "moment";
import {
  FORMAT_MEMBER_NUMBER,
  FORMAT_NUMBER,
  FORMAT_TEL_CELLPHONE_NUMBER,
} from "@/components/grid/GridCellDataFormatUtil";
import { mapActions, mapGetters } from "vuex";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

export default {
  name: "MemberGradeManagement",
  components: {
    InputText,
    InputDateRange,
    InputNumber,
    ErpButton,
    Grid,
    MemberGradeChangeLogPopup,
    memberSelectPopup,
    MemberGradeChangePopup,
    ConfigMemberGradeSettingPopup,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  filters: {
    numberWithCommas,
  },
  computed: {
    ...mapGetters("documents", ["isMemberGradeManagementExcelDownloading"]),
    isOpenPopup() {
      return (
        this.isConfigMemberGradePopupOpen === true ||
        this.isMemberSelectPopupOpen === true ||
        this.isMemberGradeChangeLogPopupOpen === true ||
        this.isMemberGradeChangePopupOpen === true
      );
    },
    memberGradeChangesMultiSelectProps() {
      const dataSource = _sortBy(
        commonCodesGetCommonCode("MEMBER_GRADE_CHANGE"),
        "sortNo"
      ).map(({ comCode: value, comName: text }) => ({ value, text }));
      return {
        allowFiltering: false,
        dataSource,
        fields: {
          text: "text",
          value: "value",
        },
        placeholder: "전체",
      };
    },
    memberGradeMultiSelectProps() {
      const dataSource = _sortBy(
        commonCodesGetCommonCode("MEMBER_GRADE"),
        "sortNo"
      ).map(({ comCode: value, comName: text }) => ({ value, text }));
      return {
        allowFiltering: false,
        dataSource,
        fields: {
          text: "text",
          value: "value",
        },
        placeholder: "전체",
      };
    },
    inputDateFromMenuItems() {
      return [
        {
          id: 1,
          text: "오늘",
        },
        {
          id: 2,
          text: "3개월",
        },
        {
          id: 3,
          text: "6개월",
        },
        {
          id: 4,
          text: "1년",
        },
      ];
    },
    shortcutMenuItems() {
      return [
        {
          id: 1,
          text: "회원등급 기준 설정",
        },
        {
          id: 2,
          text: "회원정보 등록",
        },
      ];
    },
    memberGridProps() {
      return {
        columns: [
          {
            name: "회원정보",
            columns: [
              {
                minWidth: 16,
                name: "회원명",
                width: 120,
                field: "memberName",
                type: String,
              },
              {
                minWidth: 16,
                name: "회원번호",
                width: 120,
                field: "memberNo",
                type: String,
                format: FORMAT_MEMBER_NUMBER,
                cellStyle: {
                  textAlign: "center",
                },
              },
              {
                minWidth: 16,
                name: "연락처",
                width: 120,
                field: "hpNo",
                sortable: false,
                filterable: false,
                type: String,
                format: FORMAT_TEL_CELLPHONE_NUMBER,
                cellStyle: {
                  textAlign: "center",
                },
              },
              {
                minWidth: 16,
                name: "웹 ID",
                width: 120,
                field: "webId",
                type: String,
                format: (webId) => {
                  if (["KAKAO", "NAVER"].includes(webId?.split(":")?.[0])) {
                    return webId?.split(":")?.[0];
                  } else {
                    return webId;
                  }
                },
              },
            ],
          },
          {
            name: "회원등급",
            columns: [
              {
                minWidth: 16,
                name: "현재",
                width: 120,
                field: "memberGradeBefoName",
                type: String,
                cellStyle: {
                  textAlign: "center",
                },
              },
              {
                minWidth: 16,
                name: "변경",
                width: 120,
                field: "memberGradeChangeName",
                type: String,
                cellStyle: ({ memberGradeChangeCode }) => ({
                  textAlign: "center",
                  color: commonCodesGetColorValue(
                    "MEMBER_GRADE_CHANGE",
                    memberGradeChangeCode
                  ),
                }),
              },
            ],
          },
          {
            minWidth: 16,
            name: "변경구분",
            width: 90,
            field: "memberGradeChangeCodeAbrv",
            sortable: false,
            filterable: false,
            type: String,
            iteratee: ({ memberGradeChangeCode }) => {
              return commonCodesGetCommonCode(
                "MEMBER_GRADE_CHANGE",
                false
              )?.find(({ comCode }) => comCode === memberGradeChangeCode);
            },
            cellTemplate: MemberGradeChangeCellTemplate,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "변경이력",
            width: 90,
            field: "logCnt",
            type: Number,
            format: (data) => {
              return data === 0 ? "" : numberWithCommas(data) + "건";
            },
            cellStyle: {
              textAlign: "center",
            },
            cellClass: (data) => {
              return data.logCnt === 0
                ? []
                : [this.$t("className.grid.clickArea")];
            },
          },
          {
            name: "회원실적",
            columns: [
              {
                minWidth: 16,
                name: "내장횟수",
                width: 90,
                field: "visitCnt",
                type: Number,
                format: FORMAT_NUMBER,
                cellStyle: {
                  textAlign: "right",
                },
              },
              {
                minWidth: 16,
                name: "내장점수",
                width: 90,
                field: "visitScore",
                type: Number,
                format: FORMAT_NUMBER,
                cellStyle: {
                  textAlign: "right",
                },
              },
              {
                minWidth: 16,
                name: "입장료",
                width: 120,
                field: "feeAmt",
                type: Number,
                format: FORMAT_NUMBER,
                cellStyle: {
                  textAlign: "right",
                },
              },
              {
                minWidth: 16,
                name: "식음매출",
                width: 120,
                field: "foodAmt",
                type: Number,
                format: FORMAT_NUMBER,
                cellStyle: {
                  textAlign: "right",
                },
              },
              {
                minWidth: 16,
                name: "프로샵",
                width: 120,
                field: "shopAmt",
                type: Number,
                format: FORMAT_NUMBER,
                cellStyle: {
                  textAlign: "right",
                },
              },
            ],
          },
        ],
        records: this.members,
        sizeOfRecordPerPage: 50,
        countOfAllRecords: this.totalMembers,
        rangeOfPages: 10,
        sortable: this.isSearched,
        filterable: this.isSearched,
      };
    },
  },
  async created() {
    const inputDateFrom = moment().subtract(6, "months").format("YYYY-MM-DD");

    this.inputDateRangeValue.from = inputDateFrom;
    this.inputDateRangeValue.to = getFormattedDate(new Date());

    this.$EventBus.$on(
      "fetch",
      async () => await this.fetchMemberGradeManagements()
    );
  },
  beforeDestroy() {
    this.$EventBus.$off("fetch");
  },
  data() {
    return {
      isConfigMemberGradePopupOpen: false,
      isMemberSelectPopupOpen: false,
      isMemberGradeChangeLogPopupOpen: false,
      isMemberGradeChangePopupOpen: false,
      inputDateRangeValue: { to: null, from: null },
      inputDateFromMenuItemsLabel: null,
      searchConditions: {
        visitCnt: 0,
        memberGrades: [],
        memberName: null,
        membershipId: null,
        memberGradeChanges: [],
      },
      tempSavedSearchConditions: {},
      members: [],
      totalMembers: 0,
      isSearched: false,
    };
  },
  methods: {
    ...mapActions("documents", ["downloadExcelMemberGradeManagement"]),
    async onSearchButtonClicked() {
      this.$refs["memberGrid"].resetPage();

      await this.fetchMemberGradeManagements();
    },
    async fetchMemberGradeManagements({ offset, filters, orders } = {}) {
      if (!commonCodesGetStandardInfo("memberGradeUse")) {
        this.infoToast(
          "시스템 설정의 회원등급제 사용 여부를 확인해주시기 바랍니다."
        );
        return;
      }

      if (!this.inputDateRangeValue.from || !this.inputDateRangeValue.to) {
        this.errorToast("기준일자를 입력해 주시기 바랍니다.");
        return;
      }

      const {
        orders: gridOrders,
        filters: gridFilters,
        page: gridPage,
      } = this.$refs["memberGrid"].getOrdersAndFiltersAndPage();

      const limit = this.memberGridProps.sizeOfRecordPerPage;

      const _orders = JSON.parse(JSON.stringify(orders || gridOrders || []));
      if (_orders.findIndex(({ field }) => field === "changeOrder") < 0) {
        _orders.push({
          field: "changeOrder",
          direction: "asc",
        });
      }
      _orders.push({
        field: "memberGradeChange",
        direction: "asc",
      });
      if (_orders.findIndex(({ field }) => field === "memberName") < 0) {
        _orders.push({
          field: "memberName",
          direction: "asc",
        });
      }

      const args = {
        bsnDateFrom: this.inputDateRangeValue.from,
        bsnDateTo: this.inputDateRangeValue.to,
        visitCnt: this.searchConditions.visitCnt,
        memberGrades: this.searchConditions.memberGrades,
        membershipId: this.searchConditions.membershipId,
        memberName:
          this.searchConditions.memberName === ""
            ? null
            : this.searchConditions.memberName,
        memberGradeChanges: this.searchConditions.memberGradeChanges,
        limit: limit,
        offset: offset || (gridPage - 1) * limit,
        filters: filters || gridFilters,
        orders: _orders,
      };
      this.tempSavedSearchConditions = args;

      const {
        total: totalMembers,
        records,
      } = await GolfErpAPI.fetchRecordsAndCountMemberGradeManagement(args);

      this.totalMembers = totalMembers;
      this.members = records;
      this.isSearched = true;
    },
    inputDateFromMenuSelected(args) {
      const inputDateFrom = moment(this.inputDateRangeValue.to);
      const amountList = [0, 3, 6, 12];
      this.inputDateRangeValue.from = inputDateFrom
        .subtract(amountList[args.item.id - 1], "months")
        .format("YYYY-MM-DD");
      this.inputDateFromMenuItemsLabel = this.inputDateFromMenuItems.find(
        (item) => item.id === args.item.id
      )?.text;
    },
    shortcutMenuSelected(args) {
      if (args.item.id === 1) {
        this.onConfigMemberGradePopupOpen();
      } else if (args.item.id === 2) {
        const getSelectedRows = this.$refs.memberGrid.getSelectedRows();

        if (!(getSelectedRows.length > 0)) {
          this.infoToast("회원을 선택해 주시기 바랍니다.");
          return;
        }

        const memberNo = getSelectedRows[0].memberNo;

        let routeData = this.$router.resolve({
          name: "memberInfoRegistration",
          query: {
            memberNo: memberNo,
          },
        });
        openNewWindow(routeData);
      }
    },
    onMemberGridDataFiltered(event, { page, filters, orders }) {
      if (!event) {
        return;
      }

      this.fetchMemberGradeManagements({
        offset: (page - 1) * 50,
        filters,
        orders,
      });
    },
    memberGradeChangeLogPopupClosed() {
      this.isMemberGradeChangeLogPopupOpen = false;
    },
    memberGradeChangePopupClosed(event) {
      this.isMemberGradeChangePopupOpen = false;

      if (event && event.isReload === true) {
        this.fetchMemberGradeManagements();
      }
    },
    onMemberGridCellClick(
      event,
      {
        column: { field },
        record: { logCnt, membershipId, memberName, memberGradeBefo },
      }
    ) {
      if (field === "logCnt" && logCnt > 0) {
        const popupData = {
          membershipId,
          memberName,
          memberGrade: memberGradeBefo,
        };
        this.isMemberGradeChangeLogPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.memberGradeChangeLogPopup.showPopup(popupData);
        });
      }
    },
    onMemberNameChanged() {
      this.searchConditions.membershipId = null;
    },
    onMemberNameKeyDownEnter() {
      this.searchConditions.membershipId = null;

      this.onSearchButtonClicked();
    },
    onMemberSearchButtonClicked() {
      this.isMemberSelectPopupOpen = true;

      let memberData = {};
      memberData.memberNameNo = this.searchConditions.memberName;
      memberData.isOpenedWithButton = true;
      memberData.popupType = "RESV";
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    memberSelectPopupClosed() {
      this.isMemberSelectPopupOpen = false;
    },
    memberSelectPopupConfirmed(data) {
      this.isMemberSelectPopupOpen = false;

      if (data.selectedRowData) {
        if (!data.selectedRowData.membershipId) {
          this.searchConditions.memberName = null;
          this.searchConditions.membershipId = null;
        } else {
          this.searchConditions.memberName = data.selectedRowData.memberName;
          this.searchConditions.membershipId =
            data.selectedRowData.membershipId;
        }
      }
    },
    async onMemberGradeChangeButtonClicked() {
      if (this.members.length === 0) {
        this.infoToast("정보를 조회 후 확정처리 바랍니다.");
        return;
      }

      const args = this.tempSavedSearchConditions;
      delete args.limit;
      delete args.offset;
      delete args.orders;

      this.isMemberGradeChangePopupOpen = true;
      this.$nextTick(() => {
        this.$refs.memberGradeChangePopup.showPopup(args);
      });
    },
    async onExcelClick() {
      if (50000 < this.totalMembers) {
        this.errorToast("데이터가 너무 많습니다. 5만건 이하로 시도해주세요.");
        return;
      }
      const { orders: gridOrders, filters: gridFilters } = this.$refs[
        "memberGrid"
      ].getOrdersAndFiltersAndPage();
      const columns = this.$refs["memberGrid"].getColumns();

      const _orders = JSON.parse(JSON.stringify(gridOrders || []));
      if (_orders.findIndex(({ field }) => field === "changeOrder") < 0) {
        _orders.push({
          field: "changeOrder",
          direction: "asc",
        });
      }
      _orders.push({
        field: "memberGradeChange",
        direction: "asc",
      });
      if (_orders.findIndex(({ field }) => field === "memberName") < 0) {
        _orders.push({
          field: "memberName",
          direction: "asc",
        });
      }

      this.downloadExcelMemberGradeManagement({
        payload: {
          bsnDateFrom: this.inputDateRangeValue.from,
          bsnDateTo: this.inputDateRangeValue.to,
          visitCnt: this.searchConditions.visitCnt,
          memberGrades: this.searchConditions.memberGrades,
          membershipId: this.searchConditions.membershipId,
          memberName: this.searchConditions.memberName,
          memberGradeChanges: this.searchConditions.memberGradeChanges,
          filters: gridFilters,
          orders: _orders,
          columns,
        },
      });
    },
    onConfigMemberGradePopupClose() {
      this.isConfigMemberGradePopupOpen = false;
    },
    onConfigMemberGradePopupOpen() {
      this.isConfigMemberGradePopupOpen = true;
      this.$nextTick(() => {
        this.$refs.configMemberGradeSettingPopup.showPopup();
      });
    },
  },
};
</script>
